import ttd from "../image/ttd.jpg";
import morgen from "../image/Morgen.jpg";
import P_didy from "../image/P_didy.jpg";
import Toxis from "../image/toxisssssssssss.jpg";
import Eva from "../image/Evaaaaa.jpg";

function Program() {
  return (
    <div className="w-full bg-gray-200 py-16">
      <h2 className="text-center text-2xl font-semibold text-gray-800 mb-4">
        Программа
      </h2>
      <p className="text-center text-lg text-gray-600 mb-8">13 ноября 2024</p>

      <div className="space-y-8">
        {/* Запись на программу */}
        {[
          { time: "17:00 - 17:05", title: "Начало праздника", image: null },
          { time: "17:05 - 17:30", title: "Выступление Моргенштерн", image: morgen },
          { time: "17:30 - 18:00", title: "Выступление Три дня дождя", image: ttd },
          { time: "18:00 - 18:30", title: "Драка вениками", image: null },
          { time: "18:30 - 19:00", title: "Бухаем", image: null },
          { time: "19:00 - 19:30", title: "Стриптиз (Выступление Евы Эльфи)", image: Eva },
          { time: "19:30 - 20:00", title: "Выступление P.Diddy", image: P_didy },
          { time: "20:00 - 20:30", title: "Сжигаем чучело ...(секрет)", image: null },
          { time: "20:30 - 21:00", title: "Выступление Toxis", image: Toxis },
          { time: "21:00 - 21:05", title: "Прощаемся...", image: null },
        ].map((item, index) => (
          <div key={index} className="flex items-center justify-between max-w-xl mx-auto">
            <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
              {item.time}
            </span>
            {item.image ? (
              <img
                src={item.image}
                alt={item.title}
                className="w-16 h-16 rounded-full"
              />
            ) : (
              <div className="w-4 h-4 rounded-full border-2 border-gray-300 flex items-center justify-center" />
            )}
            <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
              {item.title}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Program;