import React, { useState } from "react";

function Contacts() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full flex flex-col md:flex-row">
      {/* Левая часть с контактами */}
      <div className="w-full md:w-1/2 bg-white text-white flex flex-col justify-center p-8">
        <h2 className="text-4xl font-bold mb-6 text-black">Контакты</h2>

        {/* Адрес */}
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <span className="material-icons text-black">Адрес:</span>
            <p className="ml-2 text-black">г. Самара, ул. Чекистов, 132а</p>
          </div>
          <div className="flex items-center mb-2">
            <span className="material-icons text-black">Телефон:</span>
            <p className="ml-2 text-black">+7 777 777 7777</p>
          </div>
        </div>

        {/* График работы */}
        <div className="mb-8">
          <div className="flex items-center mb-2">
            <span className="text-black">График работы:</span>
          </div>
          <span className="text-black">24/7</span>
        </div>

        {/* Кнопка */}
        <button
          className="bg-yellow-400 text-black py-2 px-4 rounded-full hover:bg-yellow-500 transition duration-300"
          onClick={handleOpenModal}
        >
          Связаться с нами
        </button>
      </div>

      {/* Правая часть с картой */}
      <div className="w-full md:w-1/2 relative h-96">
        <iframe
          src="https://yandex.ru/map-widget/v1/?ll=50.332566%2C53.252318&mode=search&oid=1086521712&ol=biz&utm_source=share&z=18.03"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          title="Map"
          className="rounded-md"
        ></iframe>
      </div>

      {/* Модальное окно */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-11/12 max-w-md">
            <h2 className="text-2xl font-bold mb-4">Свяжитесь с нами</h2>
            <p className="mb-4">
              Нажмите на кнопку ниже, чтобы связаться через Telegram
            </p>

            {/* Кнопка Telegram */}
            <a
              href="https://t.me/maststabparty"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white py-2 px-4 rounded-full w-full text-center block hover:bg-blue-600 transition"
            >
              Telegram
            </a>

            {/* Кнопка закрытия */}
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
            >
              <span className="material-icons">X</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contacts;
